<!--
  成果管理
-->
<template>
  <div class="panel">
    <div class="panel_header tw-flex tw-justify-between tw-items-center">
      <page-header title="成果管理" />
    </div>
    <div class="panel_op">
      <el-button
        type="primary"
        size="small"
        @click="onCreate">
        发布成果
      </el-button>
    </div>
    <div class="panel_table">
      <el-table
        :data="tableData"
        :header-cell-style="headerCellStyle"
        v-loading="loading"
        @row-click="onDetail">
        <el-table-column
          label="成果名称"
          prop="title">
        </el-table-column>
        <el-table-column
          label="类型"
          prop="achievementType">
        </el-table-column>
        <el-table-column
          label="状态"
          prop="logicStatus">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.logicStatus === globalVar.ACHIEVEMENT_STATUS_0"
              type="warning">
              待审核
            </el-tag>
            <el-tag
              v-if="scope.row.logicStatus === globalVar.ACHIEVEMENT_STATUS_1"
              type="success">
              已通过
            </el-tag>
            <el-tag
              v-if="scope.row.logicStatus === globalVar.ACHIEVEMENT_STATUS_2"
              type="danger">
              已驳回
            </el-tag>
            <el-tag
              v-if="scope.row.logicStatus === globalVar.ACHIEVEMENT_STATUS_3"
              type="info">
              已下架
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="panel_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        @current-change="getAchievementList"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      loading: false,
      tableData: [],
      // 分页对象
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    headerCellStyle () {
      return {
        backgroundColor: '#F7F8F9',
        color: '#909399',
        fontWeight: 'bold'
      }
    }
  },
  created () {
    // 获取成果列表
    this.getAchievementList()
  },
  methods: {
    // 获取列表
    getAchievementList () {
      this.loading = true
      api.getAchievementList({
        ...this.pagination
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 发布需求
    onCreate () {
      window.open('/achievement-create')
    },
    // 点击详情
    onDetail (row) {
      const logicStatus = row.logicStatus
      // 已驳回
      if (logicStatus === globalVar.ACHIEVEMENT_STATUS_2) {
        window.open(`/achievement-detail-readonly/${row.id}`)
      } else {
        window.open(`/achievement-detail/${row.id}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  position: relative;
  width: 100%;
  min-width: 1030px;
  min-height: 100%;
  padding: 0 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: auto;
  & > h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #606266;
  }
  &_header {
    padding: 20px 0;
  }
  &_op {
    padding: 10px 0;
  }
  &_table {
    padding: 10px 0;
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
}
</style>
